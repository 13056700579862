<template>
  <b-container fluid>
    <b-row>
      <b-col md="6" v-for="(tip, index) in tips" :key="index">
        <iq-card>
          <template v-slot:body>
            <b-card
              overlay
              img-src="https://st4.depositphotos.com/9999814/24062/i/450/depositphotos_240620978-stock-photo-male-doctor-standing-in-the.jpg"
              img-alt="Card Image"
              text-variant="white"
              :title="tip.title"
              :sub-title="`Fecha: ${formatDate(tip.date)}`"
            >
              <b-card-text>
                <!-- <a :href="tip.url" target="_blank" rel="noopener noreferrer">Ver Video</a> -->
                <b-button @click="showModal(tip)">Ver Video</b-button>
              </b-card-text>
            </b-card>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-1" :title="data.title">
      <b-embed
        type="iframe"
        aspect="16by9"
        :src="`${data.url}?rel=0`"
        allowfullscreen
      ></b-embed>
    </b-modal>
  </b-container>
</template>

<script>
import { Toast } from './../../../mixins/toast'
import { xray } from '../../../config/pluginInit'
import api from './../../../services/api'
import Swal from 'sweetalert2'

export default {
  components: {},
  data () {
    return {
      data: {},
      type: 'add',
      tips: [],
      columns: [
        {
          name: 'Título',
          field: 'title',
          sort: ''
        },
        {
          name: 'URL',
          field: 'url',
          sort: ''
        },
        {
          name: 'Fecha',
          field: 'date_format',
          sort: ''
        }
      ]
    }
  },
  mounted () {
    xray.index()
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const { data } = await api.get('/tips')
        this.tips = data.data
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    },
    showModal (data) {
      this.data = data
      this.$bvModal.show('modal-1')
    },
    destroy (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Seguro de eliminar',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(`/tips/${id}`)
            .then((res) => {
              if (res.status === 200) {
                Toast.fire({
                  icon: 'success',
                  title: 'Eliminado correctamente'
                })
                this.getData()
              }
            })
            .catch((error) => {
              Toast.fire({
                icon: 'error',
                title: error.message
              })
            })
        }
      })
    },
    formatDate (inputDate) {
      const date = new Date(inputDate)
      const day = date.getDate()
      const month = date.getMonth() + 1 // Los meses en JavaScript están indexados desde 0 (enero es 0)
      const year = date.getFullYear()

      // Asegurarnos de que tengamos dos dígitos para el día y el mes
      const formattedDay = day < 10 ? `0${day}` : day
      const formattedMonth = month < 10 ? `0${month}` : month

      return `${formattedDay}/${formattedMonth}/${year}`
    }
  }
}
</script>
